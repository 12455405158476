/**
 * Script for setting 'theme' attribute on the document before app bootstraps.
 * Should be included via. project.json 'scripts: []' configuration
 */
(function () {
	// Load theme, default to light

	const isCookieEnabled = navigator.cookieEnabled;
	let theme;
	if (isCookieEnabled) {
		// Load theme, default to light
		theme = window.localStorage.getItem('theme');
	}
	if (!theme) {
		theme = 'light';
	}

	// Set theme attr on document
	window.document.documentElement.setAttribute('theme', theme);
})();

(function (w, d, s, l, i) {
	w[l] = w[l] || [];
	w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
	var f = d.getElementsByTagName(s)[0],
		j = d.createElement(s),
		dl = l != 'dataLayer' ? '&l=' + l : '';
	j.async = true;
	j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
	f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-NQ2WN3KK');
